import * as React from "react"
import Layout from "../components/03_organisms/Layout/Layout"

const NotFoundPage = () => (
    <Layout>
        <h1>404: Not Found</h1>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </Layout>
)

export default NotFoundPage
